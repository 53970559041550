<template>
    <div>
        <b-button size="sm" class="mx-2 my-1" variant="warning" v-if="canEdit"
                  @click="$emit(`edit`)">
            Редактировать заявку
        </b-button>
        <b-button size="sm" class="mx-2 my-1" variant="success" v-if="canRepeat"
                  @click="$emit(`repeat`)">
            Повторить заявку
        </b-button>
        <b-button size="sm" @click="$emit(`close`)" class="mx-2 my-1">
            К списку заявок
        </b-button>
        <b-button size="sm" @click.once="$emit(`sendToApprove`)" class="mx-2 my-1" variant="success" v-if="canSendToApprove">
            Отправить на согласование
        </b-button>
        <b-button size="sm" @click.once="$emit(`reject`)" class="mx-2 my-1" variant="danger" v-if="canApprove">
            Отказать
        </b-button>
        <b-button size="sm" @click.once="$emit(`approve`)" class="mx-2 my-1" variant="success" v-if="canApprove">
            Согласовать
        </b-button>
    </div>
</template>

<script>
export default {
    name: "ActionBlock",
    props: {
        isCreate: Boolean,
        canApprove: {
            type: Boolean,
            default: false
        },
        canSendToApprove: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canRepeat: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>